// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-case-case-js": () => import("./../../../src/components/templates/case/Case.js" /* webpackChunkName: "component---src-components-templates-case-case-js" */),
  "component---src-components-templates-page-cases-page-js": () => import("./../../../src/components/templates/page/CasesPage.js" /* webpackChunkName: "component---src-components-templates-page-cases-page-js" */),
  "component---src-components-templates-page-front-page-js": () => import("./../../../src/components/templates/page/FrontPage.js" /* webpackChunkName: "component---src-components-templates-page-front-page-js" */),
  "component---src-components-templates-page-page-js": () => import("./../../../src/components/templates/page/Page.js" /* webpackChunkName: "component---src-components-templates-page-page-js" */),
  "component---src-components-templates-post-js": () => import("./../../../src/components/templates/post.js" /* webpackChunkName: "component---src-components-templates-post-js" */)
}

